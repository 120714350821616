import React from 'react';
import './index.scss';
import { useCart } from '../../contexts/CartContent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useScroll } from '../../contexts/ScrollContext';

const CartComponent: React.FC = () => {
    const { t } = useTranslation();
    const { cart, payAmount } = useCart();
    const navigate = useNavigate();
    // const { setPosition } = useScroll();

    return (
        <div className='cartComponent'>
            <div className='cartComponent-inner'>
                <div className='cartComponent-inner-left'>
                    <div className='cartComponent-inner-left-qty'>
                        {cart?.length} {t('Items')}
                    </div>
                    <div className='cartComponent-inner-left-total'>
                        RM {payAmount.toFixed(2)}
                    </div>
                </div>
                <div className='cartComponent-inner-right'>
                    <div className='cartComponent-inner-right-btn' onClick={() => {
                        navigate('/cart');
                        // setPosition(0)
                    }}>
                        {t('View cart')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartComponent;
