import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';
import ProductBodyContainer from '../../components/ProductBodyContainer';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { useStore } from '../../contexts/StoreContent'
import { useTranslation } from 'react-i18next';
import ProductContainer from '../../components/ProductContainer';
import CartComponent from '../../components/CartComponent';
import { Item, Store, AdsItem, Images, Category } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import SelectedProductDetailModal from '../../components/SelectedProductDetailModal';
import { GET_ADS_BY_ID } from '../../query/advertisment'
import { useApolloClient, ApolloClient } from '@apollo/client';
import SearchProductModal from '../../components/SearchProductModal';
import { Link, Element, Events, scroller } from 'react-scroll';
import StoreDetailModal from '../../components/StoreDetailModal';
import AdvertismentContainer from '../../components/AdvertismentContainer';
import ProductDetailModal from '../../components/ProductDetailModal';

type ListItemType = {
    [key: string]: Item[];
};

const groupItemsByCategory = (items: Item[] | undefined) => {
    const groupedItems = items?.reduce((acc, item) => {
        const categoryList = item?.category_list;

        if (!categoryList || categoryList.length === 0) {
            if (!acc["Other"]) {
                acc["Other"] = [];
            }
            acc["Other"].push(item);
        } else {

            categoryList.forEach((cat) => {
                const displayName = cat?.display_name;
                if (displayName) {
                    if (!acc[displayName]) {
                        acc[displayName] = [];
                    }
                    acc[displayName].push(item);
                }
            });
        }
        return acc;
    }, {} as Record<string, Item[]>);

    if (groupedItems?.Other) {
        const otherItems = groupedItems["Other"];
        delete groupedItems["Other"];
        groupedItems["Other"] = otherItems;
    }

    return groupedItems;
};

const reorderData = (data: Record<string, any>, cat: Category[]) => {
    // Convert the data object to an array of entries
    const entries = Object.entries(data);



    const sortedEntries = entries.sort(([keyA], [keyB]) => {
        const orderA = cat.find((c) => c?.display_name?.toLowerCase() === keyA.toLowerCase())?.sort ?? Infinity;
        const orderB = cat.find((c) => c?.display_name?.toLowerCase() === keyB.toLowerCase())?.sort ?? Infinity;
        return orderA - orderB;
    });

    // console.log('cat', sortedEntries)
    return Object.fromEntries(sortedEntries);
};

const ProductPage = () => {
    const [outletModal, setOutletModal] = useState<boolean>(false);
    const [openSearchItemModal, setOpenSearchItemDetailModal] = useState<boolean>(false);
    const [openItemDetailModal, setOpenItemDetailModal] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [displayItem, setDisplayItem] = useState<Item[]>([]);
    const [selectedStore, setSelectedStore] = useState<Store | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
    const [categories, setCategories] = useState<string[]>([]);
    const [openSelectedItemModal, setOpenSelectedItemDetailModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const { storeId } = useParams();
    const { t } = useTranslation();
    const { setToCart } = useCart();
    const { getStoreDetails } = useStore()
    const [listItem, setListItem] = useState<ListItemType>({});
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const apolloClient: ApolloClient<any> = useApolloClient();
    const [ads, setAds] = useState<AdsItem[]>([]);
    const [categoryScroll, setCategoryScroll] = useState<boolean>(true);
    let adsIndex = 0;
    let itemIndex = 1;

    useEffect(() => {
        if (storeId) {
            getAdsDetail();
            getStoreDetails(storeId).then((selectedStoreData) => {
                if (selectedStoreData) {
                    // console.log('selectedStoreData', selectedStoreData)
                    setSelectedStore(selectedStoreData);
                    const products = selectedStoreData?.product_list;
                    if (!_.isEmpty(products)) {
                        setDisplayItem(products || []);
                        const data = groupItemsByCategory(products) || [];
                        const reOrder = reorderData(data, selectedStoreData?.category_list || [])
                        const allCategories = Object.keys(reOrder);
                        // console.log('categories', reOrder)
                        setCategories(allCategories);
                        setListItem(data as ListItemType);
                        if (allCategories.length > 0) {
                            setSelectedType(allCategories[0]);
                        }
                    }
                }
            }).catch((error) => {
                console.error('Error fetching store details:', error);
            });
        }
    }, [storeId]);

    const openProductDetail = (product: Item) => {
        // if (_.isEmpty(product?.option_group_list)) {
        //     setToCart(product, selectedStore, [], 1);
        // } else {
        setOpenItemDetailModal(true)
        setSelectedItem(product);
        // }
    }

    const openProductInCart = (product: Item) => {
        // if (_.isEmpty(product?.option_group_list)) {
        //     setToCart(product, selectedStore, [], -1);
        // } else {
        setSelectedItem(product);
        setOpenSelectedItemDetailModal(true)
        // }
    }

    const imageBgDisplay = (images?: Images[]) => {
        if (images && !_.isEmpty(images)) {
            return <img src={images[0]?.images_uri || ''} alt="Store Image" />
        }
    }


    const handleSetActive = (to: string) => {
        setSelectedType(to);
    };

    useEffect(() => {
        if (containerRef.current && selectedType && itemRefs.current[selectedType]) {
            const container = containerRef.current;
            const item = itemRefs.current[selectedType];

            // Get the bounding rect of the item
            const itemRect = item.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            // Calculate the scroll position
            const scrollLeft = itemRect.left - containerRect.left + container.scrollLeft;

            // Scroll to the item
            container.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    }, [selectedType]);


    const getAdsDetail = async () => {
        try {
            const companyId = await localStorage.getItem('companyId');

            // console.log('product_list', product_list)
            const input = {
                company_id: companyId,
                display_page: 'bereach_order_web_2_product_page',
                included_public_advertisement: true
            }

            const result = await apolloClient.query({
                query: GET_ADS_BY_ID,
                variables: {
                    input: input
                },
            });
            // console.log('getTotalOrderAmount', result?.data?.advertisements)
            if (result?.data?.advertisements) {
                setAds(result?.data?.advertisements?.result)
            }
        } catch (error) {
            console.error('Error calculating order amount:', error);
        }
    }


    useEffect(() => {

        const handleScroll = () => {
            setCategoryScroll(true)
        };

        window.addEventListener('touchmove', handleScroll);

        return () => {
            window.removeEventListener('touchmove', handleScroll);
        };

    }, []);

    return (
        <div className='productContainer' >
            <Header title={selectedStore?.display_name} back search info scrollWithColor
                searhAction={() =>
                    setOpenSearchItemDetailModal(true)
                }
                infoAction={() =>
                    setOutletModal(true)}
                backAction={() => {
                    navigate(-1)
                }}
            />
            <div className='productContainer-background'>
                {imageBgDisplay(selectedStore?.images)}
            </div>
            {selectedStore && (
                <ProductBodyContainer data={selectedStore} action={() => setOutletModal(true)}>
                    <div className='productContainer-type' ref={containerRef} >
                        {_.map(categories, (type, index) => (
                            <div className='productContainer-type-item' key={index}
                                ref={el => itemRefs.current[type] = el} >
                                <Link
                                    to={type}
                                    smooth={true}
                                    duration={80}
                                    spy={categoryScroll}
                                    isDynamic={true}
                                    onSetActive={handleSetActive}
                                    offset={-140}
                                    activeStyle={{ textDecoration: 'none' }}
                                    spyThrottle={500}
                                >
                                    <div className={`productContainer-type-item-label ${selectedType === type ? 'active' : ''}`} onClick={() => {
                                        setCategoryScroll(false)
                                        setSelectedType(type)
                                    }} >
                                        {t(`${type}`)}
                                    </div>
                                </Link>
                                {selectedType === type && (
                                    <div className='productContainer-type-item-borderBottom'></div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='productContainer-content'>
                        {_.map(categories, (category, index) => (
                            <div key={index}>
                                <div className='productContainer-content-category'>
                                    {t(category)}
                                </div>
                                <Element name={category} >
                                    {_.map(listItem[category], (item, i) => (
                                        <div key={i}>
                                            <ProductContainer item={item} index={i}
                                                addItem={openProductDetail}
                                                removeItem={openProductInCart} />
                                            <div>
                                                {(itemIndex++) % 3 === 0 && (
                                                    <AdvertismentContainer ads={ads} adsIndex={adsIndex++} bigger />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </Element>
                            </div>
                        ))}
                    </div>
                </ProductBodyContainer>
            )}
            <CartComponent />
            <SearchProductModal modal={openSearchItemModal}
                store={selectedStore} setModal={setOpenSearchItemDetailModal}
                data={displayItem} />
            <ProductDetailModal modal={openItemDetailModal}
                store={selectedStore} setModal={setOpenItemDetailModal}
                data={selectedItem} />
            <SelectedProductDetailModal modal={openSelectedItemModal}
                setModal={setOpenSelectedItemDetailModal} openNewProduct={(item: Item) => openProductDetail(item)}
                data={selectedItem} />
            {!_.isEmpty(selectedStore) && (
                <StoreDetailModal modal={outletModal} setModal={setOutletModal}
                    data={selectedStore} />
            )}
        </div>
    );
};

export default ProductPage;
