import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import OutletHeaderContainer from '../../components/OutletHeaderContainer';
import { PaymentMethod, CartItem } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import { useStore } from '../../contexts/StoreContent';
import PaymentCheckBox from '../../components/PaymentCheckBox';
import DeliveryComponents from '../../components/DeliveryComponent';


const CheckOutPage = () => {
  const [cartData, setCartData] = useState<CartItem[]>([]);
  const { t } = useTranslation();
  const { cart, totalAmount, payAmount, method, setSelectedPaymentGateway, selectedPaymentGateway, preOrder, taxList } = useCart();
  const { outletData, isQRExpired } = useStore();
  const [paymentMethodList, setPaymentMethodList] = useState<PaymentMethod[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    isQRExpired();
    if (cart) {
      setCartData(cart)
    }
  }, [cart]);

  useEffect(() => {
    if (outletData?.payment_types) {
      // setSelectedPaymentGateway(outletData?.payment_types[0]);
      setPaymentMethodList(outletData?.payment_types)
    }
  }, [outletData]);


  return (
    <div className='checkOutContainer'>

      <Header back title={t('Checkout')} whiteTheme backAction={() => navigate(-1)} />
      {!_.isEmpty(cartData) && (
        <>
          <div className='checkOutContainer-header'>
            <OutletHeaderContainer />
          </div>
          {method === 'delivery' && (
            <DeliveryComponents />
          )}
          <div className='checkOutContainer-totalBill'>
            <div className='checkOutContainer-totalBill-item'>
              <div className='checkOutContainer-totalBill-item-leftTitle'>
                {t('Payment Details')}
              </div>
            </div>
            <div className='checkOutContainer-totalBill-item'>
              <div className='checkOutContainer-totalBill-item-left'>
                {t('Subtotal')}
              </div>
              <div className='checkOutContainer-totalBill-item-right'>
                RM {totalAmount.toFixed(2)}
              </div>
            </div>
            {_.map(taxList, (tax, index) => (
              <div key={index} className='checkOutContainer-totalBill-item'>
                <div className='checkOutContainer-totalBill-item-left'>
                  {t(tax?.label || '')} {tax?.percentage}%
                </div>
                <div className='checkOutContainer-totalBill-item-right'>
                  RM {tax?.total_price?.toFixed(2)}
                </div>
              </div>
            ))}
          </div>
          <div className='checkOutContainer-totalAmount'>
            <div className='checkOutContainer-totalAmount-inner'>
              <div className='checkOutContainer-totalAmount-inner-left'>
                <div className='checkOutContainer-totalAmount-inner-left-label'>
                  {t('Total')}
                </div>
                <div className='checkOutContainer-totalAmount-inner-left-labelTax'>
                  {t('(incl. tax)')}
                </div>
              </div>
              <div className='checkOutContainer-totalAmount-inner-right'>
                <div className='checkOutContainer-totalAmount-inner-right-price'>
                  RM {payAmount.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className='checkOutContainer-paymentMethodContainer'>
            <div className='checkOutContainer-paymentMethodContainer-label'>
              {t('Choose payment method')}
            </div>
            <div className='checkOutContainer-paymentMethodContainer-inner'>
              {_.map(paymentMethodList, (item, index) => (
                <div key={index} onClick={() => setSelectedPaymentGateway(item)}>
                  <PaymentCheckBox
                    imageIcon={require(`../../assets/images/${item?.value}.png`)}
                    text={item?.label}
                    index={index}
                    length={paymentMethodList.length}
                    active={selectedPaymentGateway?.value === item?.value} />
                </div>
              ))}
            </div>
          </div>
          {(!_.isEmpty(cartData) && !_.isEmpty(selectedPaymentGateway)) && (
            <div className='checkOutContainer-cartBar'>
              <Button text={`Place Order`} action={() => {
                preOrder();
              }} />
            </div>
          )}

        </>
      )}
    </div>
  );
};

export default CheckOutPage;
