import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../ModalComponent';
import { useLanguage } from '../../contexts/LanguageContent';


interface SelectLanguageContainerProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}


const SelectLanguageContainer: React.FC<SelectLanguageContainerProps> = ({
  openModal, setOpenModal
}) => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguage();

  return (
    <ModalComponent modal={openModal} setModal={setOpenModal}>
      <div className='selectLangaugaContianer'>
        <div className='selectLangaugaContianer-label'>
          {t('Change Language')}
        </div>
        <div className='selectLangaugaContianer-selection'>
          <div className='selectLangaugaContianer-selection-item' onClick={() => {
            changeLanguage('bm');
            setOpenModal(false)
          }}>
            Bahasa Melayu
          </div>
          <div className='selectLangaugaContianer-selection-item' onClick={() => {
            changeLanguage('cn')
            setOpenModal(false)
          }}>
            华文
          </div>
          <div className='selectLangaugaContianer-selection-item' onClick={() => {
            changeLanguage('en')
            setOpenModal(false)
          }}>
            English
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default SelectLanguageContainer;
