import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextType {
  language: string;
  changeLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const { i18n } = useTranslation();

  useEffect(() => {
    loadSavedLanguage();
  }, []);

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  const loadSavedLanguage = () => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    // console.log('savedLanguage', savedLanguage)
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    } else {
      const language = navigator.language;
      if (language === 'zh-CN') {
        setLanguage('cn');
        i18n.changeLanguage('cn');
      } else if (language === 'ms-MY') {
        setLanguage('bm');
        i18n.changeLanguage('bm');
      } else {
        setLanguage('en');
        i18n.changeLanguage('en');
      }

    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};