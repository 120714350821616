import React, { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';

const Spay = () => {
  const formSP = useRef<HTMLFormElement>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const data = urlParams.get('data');
  const [spayPaymentData, setSpayPaymentData] = useState<object>({});


  useEffect(() => {
    if (data) {
      const decodedArray = JSON.parse(decodeURIComponent(data));
      setSpayPaymentData(decodedArray);
    }
  }, [data]);


  useEffect(() => {
    if (spayPaymentData && formSP.current) {
      formSP.current.submit();
    }
  }, [spayPaymentData]);



  const SarawakPayForm = () => {
    return (
      <form method="POST" action={_.get(spayPaymentData, ['url'], '')} ref={formSP} >
        <input type="hidden" name="formData" value={_.get(spayPaymentData, ['encrypted_data'], '')} />
      </form>
    )
  }


  return <>
    {SarawakPayForm()}
  </>;
};

export default Spay;
