import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../languages/en.json';
import cnTranslation from '../languages/cn.json';
import myTranslation from '../languages/bm.json';

const resources = {
  en: { translation: enTranslation },
  cn: { translation: cnTranslation },
  bm: { translation: myTranslation },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;