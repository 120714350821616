import React, { useEffect } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import { useNotification } from '../../contexts/NotificationContext';
import * as _ from 'lodash';
import { useLocation } from 'react-router-dom';
import homeIcon from '../../assets/icons/home.svg';
import shopIcon from '../../assets/icons/shop.svg';
import billIcon from '../../assets/icons/bill.svg';
import profileIcon from '../../assets/icons/profile.svg';

interface FooterProps {
    display: boolean;
}

const Footer: React.FC<FooterProps> = ({ display }) => {
    const navigate = useNavigate();
    const { outletData } = useStore()
    const { pathname } = useLocation();
    const { showNotification } = useNotification();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);

    const goLatestOrder = async () => {
        const latest_merOrderNo = localStorage.getItem('latest_merOrderNo');
        if (latest_merOrderNo) {
            navigate(`/paymentStatus?merOrderNo=${latest_merOrderNo}`);
        } else {
            const bill_no = await localStorage.getItem('latest_bill_group_no');
            if (bill_no) {
                navigate(`/order/${bill_no}`)
            } else {
                showNotification('No recent bill found.', 'info');
            }
        }
    }


    if (display) {
        return (
            <div className='footerContainer'>
                <div className='footerContainerInner'>
                    <div className='footerContainerInner-item' onClick={() => {
                        // if (!_.isEmpty(outletData)) {
                        //     navigate('/outlet')
                        // } else {
                        navigate('/home')
                        // }
                    }}>
                        <img src={homeIcon} />
                    </div>
                    <div className='footerContainerInner-item' onClick={() => {
                        goLatestOrder()
                    }}>
                        <img src={shopIcon} />
                    </div>
                    <div className='footerContainerInner-item' onClick={() => {
                        navigate('/orderHistory')

                    }}>
                        <img src={billIcon} />
                    </div>
                    <div className='footerContainerInner-item' onClick={() => {
                        // navigate('/account')
                        showNotification('Coming soon', 'info');
                    }}>
                        <img src={profileIcon} />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Footer;
