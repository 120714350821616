import React, { useEffect, useState } from 'react';
import { Scanner, IScannerClassNames, IScannerComponents } from '@yudiel/react-qr-scanner';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import closeIcon from '../../assets/icons/close.svg'
import { useTranslation } from 'react-i18next';
import ModalComponent from '../ModalComponent';
import './index.scss';

interface QrScannerModalProps {
    openCamera: boolean;
    setOpenCamera: (value: boolean) => void;
}

interface QRData {
    rawValue: string;
}

const classNames: IScannerClassNames = {
    container: 'qr-scanner-container',
    video: 'qr-scanner-video',
};


const components: IScannerComponents = {
    finder: false
};

const QrScannerModal: React.FC<QrScannerModalProps> = ({
    openCamera = false,
    setOpenCamera,
}) => {
    const [scanned, setScanned] = useState<boolean>(true);
    const navigate = useNavigate();
    const { getQRDetails, storeQr } = useStore();
    const { t } = useTranslation();

    const receiveData = (data: QRData) => {
        setScanned(false);
        setOpenCamera(false);
        const link = data.rawValue;
        // console.log('qr', link)
        const splitData = link.split('/');
        const qr = splitData[splitData.length - 1];
        if (qr) {
            storeQr(qr);
            getQRDetails(qr, "null")
            navigate('/outlet')
        }
    }

    // useEffect(() => {
    //     // console.log('outletData', outletData)
    //     if (!_.isEmpty(outletData)) {
    //         navigate('/outlet')
    //     }
    // }, [outletData]);


    useEffect(() => {
        setScanned(openCamera);
        // console.log('openCamera', openCamera)
    }, [openCamera]);

    const handleClose = () => {
        setOpenCamera(false)
        setScanned(false)
    };

    return (
        <>
            <ModalComponent modal={openCamera} setModal={setScanned} fullHeight>
                <div className='qr-scanner'>
                    <div className='qr-scanner-wrapper'>
                        <Scanner onScan={(result) => {
                            receiveData(result[0])
                        }}
                            paused={!scanned}
                            allowMultiple={false}
                            classNames={classNames}
                            components={components}
                        >
                            <div className='qr-scanner-closeBtn' onClick={() => handleClose()}>
                                <img src={closeIcon} />
                                <div>{t('Scan QR code')}</div>
                            </div>
                            <div className='qr-scanner-icon-contianer'>
                                <img src={require('../../assets/images/scanner.png')} />
                            </div>
                        </Scanner>
                    </div>
                </div>
            </ModalComponent>
        </>
    );
};

export default QrScannerModal;
