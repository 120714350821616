import { gql } from '@apollo/client';

export const SPAY_STATUS = gql`
  query SpayPaymentQuery($input: SpayPaymentQueryInput!) {
    spayPaymentQuery(input: $input) {
      status
      message
      additional_info {
        bill_group_no
      }
    }
  }
`;