import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import OutletBodyContainer from '../../components/OutletBodyContainer';
import CheckBoxForm from '../../components/CheckBoxForm';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import InputBox from '../../components/InputBox';
import OutletDetailModal from '../../components/OutletDetailModal';
import ModalComponent from '../../components/ModalComponent';
import { useStore } from '../../contexts/StoreContent'
import { useCart } from '../../contexts/CartContent';
import { Outlet, Company } from '../../types/dataType';
import SelectLanguageContainer from '../../components/SelectLanguageContainer';
import { useTranslation } from 'react-i18next';


const OutletPage = () => {
    const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
    const [listMethod, setListMethod] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedOutlet, setSelectedOutlet] = useState<Outlet | null>(null);
    const [companyData, setCompanyData] = useState<Company>();
    const [modal, setModal] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [languageModal, setLanguageModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const { outletData, isQRExpired } = useStore()
    const { method, selectMethod, selectTable, tableNumber } = useCart();
    const { t } = useTranslation();

    useEffect(() => {
        isQRExpired();
        if (!_.isEmpty(outletData)) {
            setSelectedOutlet(outletData);
            setCompanyData(outletData?.company)
            selectTable(outletData?.name)
            // console.log('outletData', outletData)
            if (outletData.type) {
                setListMethod(outletData.type);
                if (tableNumber) {
                    setInputValue(tableNumber)
                }
                if (method) {
                    const selected = outletData?.type?.find(storeItem => storeItem === method);
                    if (selected) {
                        setSelectedMethod(selected);
                    } else {
                        selectMethod(outletData.type[0]);
                        setSelectedMethod(outletData.type[0])
                    }
                } else {
                    selectMethod(outletData.type[0]);
                    setSelectedMethod(outletData.type[0])
                }
            }
        }
    }, [outletData, method]);

    useEffect(() => {
        if (selectedMethod) {
            // console.log('selectedMethod', selectedMethod)
            selectMethod(selectedMethod);
        }
    }, [selectedMethod]);

    const submit = (method: string) => {
        if (method === 'dine-in-table') {
            setOpenModal(true)
        } else {
            if (outletData?.company?.store_list && outletData.company.store_list.length === 1) {
                navigate(`/product/${outletData?.company?.store_list?.[0]?._id}`)
            } else {
                navigate('/store')
            }
        }
    }

    const submitTable = (table: string) => {
        selectTable(table);
        if (outletData?.company?.store_list && outletData.company.store_list.length === 1) {
            navigate(`/product/${outletData?.company?.store_list?.[0]?._id}`)
        } else {
            navigate('/store')
        }
    }

    return (
        <div className='outletContainer'>
            <Header language languageAction={() => setLanguageModal(true)} />
            {selectedOutlet && (
                <>
                    <div className='outletContainer-background'>
                        <img src={require('../../assets/images/outletBg.png')} />
                    </div>
                    <OutletBodyContainer data={companyData} open={() => setModal(true)}>
                        <div className='outletContainer-content'>
                            <div className='outletContainer-content-title  mt-3 mb-2'>
                                {t('Start an order')}
                            </div>
                            <CheckBoxForm data={listMethod} setSelectedData={setSelectedMethod}
                                selectedData={selectedMethod} />
                            {method && (
                                <div className='outletContainer-content-btn mt-2'>
                                    <Button text={'Continue'} action={() => submit(method)} />
                                </div>
                            )}
                        </div>
                    </OutletBodyContainer>
                    {!_.isEmpty(companyData) && (
                        <OutletDetailModal modal={modal} setModal={setModal} data={companyData} />
                    )}
                    <ModalComponent modal={openModal} setModal={setOpenModal} >
                        <div className='outletContainer-tableForm'>
                            <div className='outletContainer-tableForm-input'>
                                <InputBox deleteIcon inputValue={inputValue} text={'Table Number'}
                                    setInputValue={setInputValue} />
                            </div>
                            <div className='outletContainer-tableForm-btn'>
                                <Button text={'Continue'} action={() => submitTable(inputValue)} />
                            </div>
                        </div>
                    </ModalComponent>
                    <SelectLanguageContainer setOpenModal={setLanguageModal} openModal={languageModal} />
                </>
            )}
        </div>
    );
};

export default OutletPage;
