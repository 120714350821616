import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import * as _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrder } from '../../contexts/OrderContent';
import { SPAY_STATUS } from '../../query/spayStatus';
import dayjs from 'dayjs';
import { useCart } from '../../contexts/CartContent';
import { useLoading } from '../../contexts/LoadingContent';
import { useNotification } from '../../contexts/NotificationContext';
import { useApolloClient, ApolloClient } from '@apollo/client';

const PaymentStatusPage = () => {
  const [searchParams] = useSearchParams();
  const apolloClient: ApolloClient<any> = useApolloClient();
  const merOrderNo = searchParams.get('merOrderNo');
  const [paymentStatus, setPaymentStatus] = useState<String>('pending');
  const { getOrderDetails } = useOrder();
  const { stroageToLocalOrderHistory, cleanData } = useCart();
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  const [firstLoaded, setFrstLoaded] = useState<Boolean>(true);
  const { t } = useTranslation();

  const navigate = useNavigate();


  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (paymentStatus === 'pending' && merOrderNo && !firstLoaded) {
      intervalId = setInterval(() => {
        checkMerOrderStatus(merOrderNo);
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [merOrderNo, paymentStatus, firstLoaded]);

  useEffect(() => {
    if (paymentStatus === 'pending' && merOrderNo && firstLoaded) {
      setTimeout(() => {
        checkMerOrderStatus(merOrderNo);
        setFrstLoaded(false)
      }, 500);
    }
  }, [merOrderNo, paymentStatus, firstLoaded]);


  useEffect(() => {
    if (!merOrderNo) {
      navigate('/')
      window.alert('merOrderNo no found.');
    }
  }, [merOrderNo]);

  const checkMerOrderStatus = async (merOrderNo: string) => {
    if (!merOrderNo) return;
    try {
      const result = await apolloClient.query({
        query: SPAY_STATUS,
        variables: { input: { merOrderNo } },
      });
      // console.log('result?.data', result?.data)
      const { spayPaymentQuery } = result?.data || {};
      if (spayPaymentQuery?.message === 'Communication success') {
        if (spayPaymentQuery?.status === 'waiting') {
          // showNotification('Payment in progress')
        } else if (spayPaymentQuery?.status === 'success') {
          checkOrderDetails(spayPaymentQuery?.additional_info?.bill_group_no);
        } else {
          await localStorage.removeItem('latest_merOrderNo');
          setPaymentStatus('fail');
        }
      } else {
        await localStorage.removeItem('latest_merOrderNo');
        setPaymentStatus('fail');
      }
    } catch (err) {
      showNotification('Something went wrong.', 'error');
      setPaymentStatus('fail');
      throw err;
    }
  };

  const onClickCheckMerOrderStatus = async (merOrderNo: string) => {
    if (!merOrderNo) return;
    try {
      const result = await apolloClient.query({
        query: SPAY_STATUS,
        variables: { input: { merOrderNo } },
      });
      // console.log('result?.data', result?.data)
      const { spayPaymentQuery } = result?.data || {};
      if (spayPaymentQuery?.message === 'Communication success') {
        if (spayPaymentQuery?.status === 'waiting') {
          showNotification('Payment in progress')
        } else if (spayPaymentQuery?.status === 'success') {
          checkOrderDetails(spayPaymentQuery?.additional_info?.bill_group_no);
        } else {
          await localStorage.removeItem('latest_merOrderNo');
          setPaymentStatus('fail');
        }
      } else {
        await localStorage.removeItem('latest_merOrderNo');
        showNotification(spayPaymentQuery?.message, 'error');
        setPaymentStatus('fail');
      }
    } catch (err) {
      showNotification('Something went wrong.', 'error');
      setPaymentStatus('fail');
      throw err;
    }
  };

  const checkOrderDetails = (groupId: any) => {
    getOrderDetails(groupId)
      .then((orderDataResult) => {
        if (orderDataResult) {
          localStorage.removeItem('latest_merOrderNo');
          const companyName = localStorage.getItem('companyName')
          const data = {
            'company_name': companyName,
            'date': dayjs().format('dddd, MMM D hh:mm'),
            'payment_method': orderDataResult?.payment_type,
            'total_amount': orderDataResult?.group_net_price,
            'order_group_id': orderDataResult?.bill_group_no,
          }
          stroageToLocalOrderHistory(data);
          cleanData();
          navigate(`/order/${orderDataResult?.bill_group_no}`)
        } else {
          localStorage.removeItem('latest_merOrderNo');
          setPaymentStatus('fail')
        }
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
      });
  };

  return (
    <div className='cartContainer'>
      <>
        <div className='cartContainer-empty'>
          {paymentStatus === 'pending' && (
            <>
              <div className='cartContainer-empty-notFound'>
                <img src={require('../../assets/images/logo.png')} />
                <div className='cartContainer-empty-title'>
                  {t('Your payment is being processed')}
                </div>
                <div className='cartContainer-empty-desc'>
                  {t('Your payment is currently in progress. Please do not close the page.')} No.{merOrderNo}
                </div>
                <div className='cartContainer-cartBar'>
                  <Button text={`Check Status`} action={() => {
                    onClickCheckMerOrderStatus(merOrderNo ?? '');
                  }} />
                </div>
              </div>
            </>
          )}
          {paymentStatus === 'fail' && (
            <>
              <div className='cartContainer-empty-notFound'>
                <img src={require('../../assets/images/Close.png')} />
                <div className='cartContainer-empty-title'>
                  {t('Payment Failed')}
                </div>
                <div className='cartContainer-empty-desc'>
                  {t('Payment was unsuccessful. Please try using a different payment method.')}
                </div>
              </div>
              <div className='cartContainer-cartBar'>
                <Button text={`Pay Again`} action={() => {
                  navigate('/cart')
                }} />
              </div>
            </>
          )}
        </div>

      </>
    </div>
  );
};

export default PaymentStatusPage;
