import { gql } from '@apollo/client';

export const GENERATE_ORDER = gql`
  mutation GenerateOrder($input: GenerateOrderInput!) {
    generateOrder(input: $input) {
      bill_group_no
      payment_type
      additional_info {
        spay {
          url
          encrypted_data
          merOrderNo
        }
      }
    }
  }
`;